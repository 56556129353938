import { useEffect } from 'react';
import PropTypes from 'prop-types';

const BuyIndexContainer = ({ buy }) => {
    const clearDataAndRedirectToStripe = url => {
        global.localStorage.removeItem('buyProductId');
        global.localStorage.removeItem('buyFromLandinng');
        global.localStorage.removeItem('buyPromoCode');
        global.localStorage.removeItem('buySource');
        window.location = url;
    };

    const handleBuy = async () => {
        const productVariantId = +global.localStorage.getItem('buyProductId');
        const cancelUrl = global.localStorage
            .getItem('buyFromLandinng')
            .replaceAll('|', '&');
        const promoCode = global.localStorage.getItem('buyPromoCode');
        const source = global.localStorage.getItem('buySource');
        const productPrice = global.localStorage.getItem('buyProductPrice');

        const userEmail = global.localStorage.getItem('userEmail');
        const userId = global.localStorage.getItem('userId');

        if (productVariantId && cancelUrl) {
            if (window) {
                // Clear the previous ecommerce object.
                window.dataLayer.push({ ecommerce: null });

                const dataLayerObject = {
                    event: 'begin_checkout',
                    ecommerce: {
                        currency: 'USD',
                        items: [
                            {
                                item_id: productVariantId,
                                price: productPrice,
                            },
                        ],
                    },
                    user_data: {
                        email: userEmail,
                        user_id: userId,
                    },
                };
                window.dataLayer.push(dataLayerObject);
            }

            const request = {
                productVariantId,
                successUrl: `${process.env.REACT_APP_URL}/buy/thank-you/%PAYMENT_TOKEN%`,
                cancelUrl,
            };

            if (promoCode) {
                request.promoCode = promoCode;
            }

            if (source) {
                request.source = source;
            }

            try {
                const response = await buy(request);
                const {
                    __typename,
                    url,
                    paymentWithoutPromotionCode,
                } = response.data.sale.createSubscription;

                if (__typename === 'Payment') {
                    clearDataAndRedirectToStripe(url);
                } else if (__typename === 'SubscriptionCreateException') {
                    throw new Error(
                        `Failed to create subscriprion, SubscriptionCreateException`
                    );
                } else if (__typename === 'ValidationException') {
                    throw new Error(
                        `Failed to create subscriprion, ValidationException`
                    );
                } else if (__typename === 'UnauthorizedException') {
                    throw new Error(
                        `Failed to create subscriprion, UnauthorizedException`
                    );
                } else if (__typename === 'InvalidPromoCodeException') {
                    if (paymentWithoutPromotionCode.__typename === 'Payment') {
                        clearDataAndRedirectToStripe(
                            paymentWithoutPromotionCode.url
                        );
                    } else if (
                        paymentWithoutPromotionCode.__typename ===
                        'SubscriptionCreateException'
                    ) {
                        throw new Error(
                            `Failed to create subscriprion, SubscriptionCreateException`
                        );
                    }
                }
            } catch (e) {
                throw new Error(
                    `Failed to create subscriprion, got error: ${e}`
                );
            }
        }
    };

    useEffect(() => {
        handleBuy();
    }, []);

    return null;
};

BuyIndexContainer.propTypes = {
    buy: PropTypes.func.isRequired,
};

export default BuyIndexContainer;
